import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Observer } from 'gsap/Observer'

export function initGsap() {
    setTimeout(() => {

        var credit = document.querySelector('#credits');
        var rollings = document.querySelectorAll('.roll-link');


        credit.addEventListener('mouseover', () => {
            gsap.to('#credits h6', { y: -15, duration: 0.8, ease: "power4.out" })
        })
        credit.addEventListener('mouseleave', () => {
            gsap.to('#credits h6', { y: 0, duration: 0.8, ease: "power4.out" })
        })

        for (let i = 0; i < rollings.length; i++) {
            let rolling = rollings[i];

            rolling.addEventListener('mouseover', () => {
                let h6 = rolling.querySelector('.h6');
                gsap.to(h6, { y: -15, duration: 0.8, ease: "power4.out" })
            })
            rolling.addEventListener('mouseleave', () => {
                let h6 = rolling.querySelector('.h6');
                gsap.to(h6, { y: 0, duration: 0.8, ease: "power4.out" })
            })
        }

    }, 500)
}

export function initPortfolio() {
    setTimeout(() => {
        gsap.registerPlugin(ScrollTrigger, Observer);

        let sections = document.querySelectorAll("section"),
            images = document.querySelectorAll(".bg"),
            outerWrappers = gsap.utils.toArray(".outer"),
            innerWrappers = gsap.utils.toArray(".inner"),
            currentIndex = -1,
            wrap = gsap.utils.wrap(0, sections.length),
            animating;

        gsap.set(outerWrappers, { yPercent: 100 });
        gsap.set(innerWrappers, { yPercent: -100 });

        function gotoSection(index, direction) {
            index = wrap(index); // make sure it's valid
            animating = true;
            let fromTop = direction === -1,
                dFactor = fromTop ? -1 : 1,
                tl = gsap.timeline({
                    defaults: { duration: 1.25, ease: "power4.inOut" },
                    onComplete: () => animating = false
                });
            if (currentIndex >= 0) {
                // The first time this function runs, current is -1
                gsap.set(sections[currentIndex], { zIndex: 0 });
                tl.to(images[currentIndex], { yPercent: -15 * dFactor })
                    .set(sections[currentIndex], { autoAlpha: 0 });
            }
            gsap.set(sections[index], { autoAlpha: 1, zIndex: 1 });
            tl.fromTo([outerWrappers[index], innerWrappers[index]], {
                yPercent: i => i ? -100 * dFactor : 100 * dFactor
            }, {
                yPercent: 0
            }, 0)
                .fromTo(images[index], { yPercent: 15 * dFactor }, { yPercent: 0 }, 0)

            currentIndex = index;
        }

        Observer.create({
            type: "wheel,touch,pointer",
            wheelSpeed: -1,
            onDown: () => !animating && gotoSection(currentIndex - 1, -1),
            onUp: () => !animating && gotoSection(currentIndex + 1, 1),
            tolerance: 10,
            preventDefault: true
        });

        gotoSection(0, 1);

        // original: https://codepen.io/BrianCross/pen/PoWapLP
        // horizontal version: https://codepen.io/GreenSock/pen/xxWdeMK

    }, 1000)
}