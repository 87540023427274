<template>
    <div id="credits">
        <a href="http://portfolio.alexandrebeltramini.com.br/">
            <div class="credits-wrapper">
                <h6>
                    Code by AB
                </h6>
                <h6>
                    Code by AB
                </h6>
            </div>
        </a>
    </div>
    <div id="social">
        <a href="https://www.instagram.com/beltdev/" target="_blank">
            <RollingTitle title="Instagram"/>
        </a>
        <a href="https://www.linkedin.com/in/alexandre-beltramini/" target="_blank">
            <RollingTitle title="Linkedin"/>
        </a>
    </div>
</template>

<script>
import RollingTitle from './RollingTitle.vue';

export default {
    components:{
        RollingTitle
    }
}
</script>