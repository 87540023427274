<template>
  <!--
Sections animate in and out on scroll. Scroll up or down and the sections will wrap around after hitting the start or end. Uses GSAP for the animations.
-->
  <div class="intro">
    <h1 id="logo-intro">
      <span id="item-a" class="item-ab">A</span>
      <span id="item-b" class="item-ab">B</span>
    </h1>
  </div>

  <section :class="'project' + index" v-for="(project, index) in projects" :key="project.id">
    <div class="outer">
      <div class="inner">
        <div class="bg one">
          <a :href=project.link target="_blank">
            <h2 class="section-heading">{{ project.title }}</h2>
          </a>
        </div>
      </div>
    </div>

  </section>

</template>

<script>
import { initPortfolio } from '@/utils/initGsap';
import axios from 'axios'

/* https://codepen.io/BrianCross/pen/PoWapLP" Original By Brian*/

export default {
  data() {
    return {
      projects: []
    }
  },
  mounted() {
    initPortfolio()

    axios.get('projects.json').then(res => {
      this.projects = res.data
      console.log(res)
    }).catch(error => {
      console.error(error)
    })

  }
}

</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");

$bg-gradient: linear-gradient(180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.3) 100%);

* {
  box-sizing: border-box;
  user-select: none;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  color: var(--branco);
  background: var(--preto);
  font-family: "Cormorant Garamond", serif;

}

.intro {
  position: fixed;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);

  #logo-intro {
    margin: 0;
    font-family: CotfordVar, serif;
    color: var(--branco);
    opacity: 0.1;
    font-size: 80px;
    height: fit-content;

    #item-a {
      font-weight: 300;
    }

    #item-b {
      font-weight: 500;
    }
  }
}

h2 {
  font-size: clamp(1rem, 5vw, 5rem);
  font-weight: 200;
  text-align: center;
  letter-spacing: 0.05em;
  //margin-right: -0.5em;
  color: hsl(0, 0, 95%);
  width: 90vw;
  max-width: 1200px;
  font-family: CotfordVar, serif !important;

}

header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  width: 100%;
  z-index: 3;
  height: 7em;
  font-family: "Bebas Neue", sans-serif;
  font-size: clamp(0.66rem, 2vw, 1rem);
  letter-spacing: 0.5em;
}

section {
  height: 100%;
  width: 100%;
  top: 0;
  position: fixed;
  visibility: hidden;


  .outer,
  .inner {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    position: relative;

    .mockup {
      position: absolute;

    }

  }

  .bg {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-size: cover;
    background-position: center;

    h2 {
      z-index: 2;
    }

    .clip-text {
      overflow: hidden;
    }

    a {
      transition: 0.3s;
    }

    a:hover {
      opacity: 0.5;
    }
  }
}

.project0 {
  .bg {
    background-image: $bg-gradient,
      url("/public/images/projects-mockup/Mockup-lizandra.avif");
  }
}

.project1 {
  .bg {
    background-image: $bg-gradient,
      url("/public/images/projects-mockup/Mockup-graca-de-dormir.avif");
  }
}

.project2 {
  .bg {
    background-image: $bg-gradient,
      url("/public/images/projects-mockup/Mockup-evahc.avif");
    background-position: center top;

  }
}

.project3 {
  .bg {
    background-image: $bg-gradient,
      url("/public/images/projects-mockup/Mockup-mindsetipo.avif");
  }
}

.project4 {
  .bg {
    background-image: $bg-gradient,
      url("/public/images/projects-mockup/Mockup-Cobogo.avif");
    background-position: 50% 45%;
  }
}

.project5 {
  .bg {
    background-image: $bg-gradient,
      url("/public/images/projects-mockup/Mockup-lb.avif");
  }
}


h2 * {
  will-change: transform;
}
</style>