<template>
    <div id="sobre">
        <div class="sobre-content limit lang">
            <h3>
                <div v-html="$t('about.heading')"></div>
            </h3>
            <div v-html="$t('about.about')"></div>
        </div>
    </div>
</template>

<script>

export default {
    
}
</script>

<style lang="scss">
#sobre {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;

    .sobre-content {
        max-width: 750px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    h3 {
        font-size: clamp(22px, 5vw, 3rem);
        text-transform: initial;
        font-weight: 100;
        letter-spacing: 0 !important;
        line-height: 1.2em;
        margin: 0 0 20px;

        span {
            font-weight: 400;
        }
    }

}
</style>