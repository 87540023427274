<template>
  <nav>
    <router-link class="lang" to="/">
      <RollingTitle :title="$t('menu.home')" />
    </router-link>
    <router-link class="lang" to="/about">
      <RollingTitle :title="$t('menu.about')" />
    </router-link>
    <router-link class="lang" to="/contact">
      <RollingTitle :title="$t('menu.contact')" />
    </router-link>
  </nav>

  <div class="route">
    <transition mode="out-in" name="fade">
      <router-view />
    </transition>
  </div>

  <div class="language">
    <RollingTitle @click="changeLanguage('PT')" title="pt" :class="{ inactive: currentLocale !== 'PT' }" />
    <span></span>
    <RollingTitle @click="changeLanguage('EN')" title="en" :class="{ inactive: currentLocale !== 'EN' }" />
  </div>

  <CreditsFooter />
</template>

<script>
import gsap from 'gsap';
import CreditsFooter from './components/CreditsFooter.vue';
import RollingTitle from './components/RollingTitle.vue';
import { initGsap } from './utils/initGsap';


export default {
  components: {
    CreditsFooter,
    RollingTitle
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;  // Pega o idioma atual do i18n
    }
  },
  methods: {
    changeLanguage(locale) {
      if (this.$i18n.locale !== locale) {
        gsap.to(".lang", { opacity: 0, duration: 0.5 })
        setTimeout(() => {
          this.$i18n.locale = locale;
          document.cookie = `locale=${locale}; path=/`
          gsap.to(".lang", { opacity: 1, duration: 0.5 })
        }, 500)
      }
    }
  },
  mounted() {
    gsap.set("body", { opacity: 0 })
    gsap.to("body", { opacity: 1, duration: 1, ease: "power1.out", delay: 0.7 })

    initGsap()
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'CotfordVar';
  src: url('/public/fonts/CotfordVar/CotfordVar.ttf') format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('/public/fonts/NeueHaasGrotesk/NeueHaasGroteskDisplay-Thin.otf') format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('/public/fonts/NeueHaasGrotesk/NeueHaasGroteskDisplay-ExtLt.otf') format('opentype');
  font-weight: 200
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('/public/fonts/NeueHaasGrotesk/NeueHaasGroteskDisplay-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('/public/fonts/NeueHaasGrotesk/NeueHaasGroteskDisplay-SemLt.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('/public/fonts/NeueHaasGrotesk/NeueHaasGroteskDisplay-Reg.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('/public/fonts/NeueHaasGrotesk/NeueHaasGroteskDisplay-Medium.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('/public/fonts/NeueHaasGrotesk/NeueHaasGroteskDisplay-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('/public/fonts/NeueHaasGrotesk/NeueHaasGroteskDisplay-Black.otf') format('opentype');
  font-weight: 800;
}

:root {
  --azul: #485CC7;
  --preto: #202020;
  --branco: #f5f5f5;

  --cotford: 'CotfordVar', serif;
  --neue: 'Neue Haas Grotesk Display', sans-serif;
}


html,
body {
  overflow: hidden;
}

.limit {
  padding: 40px 30px;
}

#app {
  font-family: var(--neue);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212121;
}
.route{
  position: fixed;
}
a {
  color: #fff;
  text-decoration: none;
}

p {
  color: var(--branco);
  line-height: 1.4em;
  letter-spacing: 0.05em;
  font-size: 15px;
  font-weight: 500;

  @media(max-width:700px) {
    font-size: 11px;
  }
}

h2 {
  text-transform: uppercase;
}

nav {
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 100;
  gap: 40px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;

  @media(max-width:700px) {
    gap: 20px;
  }

  h6 {
    margin: 0;
    text-transform: uppercase;
  }

  a {
    font-weight: 500;
    color: #969696;
    letter-spacing: 0.05em;

    &.router-link-exact-active {
      color: var(--branco);
    }
  }
}

h3 {
  color: var(--branco);
  font-family: var(--cotford);
  font-weight: 300;
  font-size: 30px;
  text-transform: initial;
}

.language {
  display: flex;
  z-index: 1000;
  position: fixed;
  right: 30px;
  top: 30px;
  gap: 10px;
  color: var(--branco);

  span {
    width: 2px;
    background-color: var(--branco);
    height: 8px;
    transform: translatey(3px)
  }

  .active {
    opacity: 1;
  }

  .inactive {
    color: #969696 !important;
  }
}

.roll-link {
  cursor: pointer;
  width: fit-content;
  z-index: 100;

  .roll-wrapper {
    height: 15px;
    overflow: hidden;

    h6 {
      margin: initial;
      display: flex;
      line-height: 1.4em;
      text-transform: uppercase;
    }
  }
}

#social {
  display: flex;
  gap: 40px;
  z-index: 1000;
  position: fixed;
  bottom: 30px;
  right: 30px;

  @media(max-width:700px) {
    gap: 20px;
  }
}

#credits {
  cursor: pointer;
  width: fit-content;
  z-index: 1000;
  position: fixed;
  bottom: 30px;
  left: 30px;

  .credits-wrapper {
    height: 15px;
    overflow: hidden;

    h6 {
      margin: initial;
      display: flex;
      line-height: 1.4em;
      text-transform: uppercase;
    }
  }
}


//TRANSIÇÃO

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
