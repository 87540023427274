<template>
    <div class="roll-link">
        <div class="roll-wrapper">
            <div class="h6">
                <h6>{{ title }}</h6>
                <h6>{{ title }}</h6>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:[ "title"]
}
</script>